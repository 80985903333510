import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { postLogin } from '../../clients/phpApp';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import './styles/Login.css';
import { useRef, useEffect } from 'react';
import {Spinner} from "react-bootstrap";

function Login(props) {
  const { handleLoginClick, setIsLoggedIn, setShowLogin } = props;
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { Formik } = formik;
  const containerRef = useRef();
  const [errorsResponse, setErrorsResponse] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false);

  const Schema = yup.object().shape({
    email: yup.string().email("Ingrese un correo electrónico válido").required("El correo electrónico es requerido."),
    password: yup.string().required('La clave es requerida.'),
  });

  const handlePost = async (email, password, grant_type, client_id, client_secret) => {
    setIsSubmitting(true);
    try {
      const data = { email, password, grant_type, client_id, client_secret };
      const response = await postLogin(data);
      setTimeout(() => {
        setIsSubmitting(false);
      }, 2000);

      localStorage.setItem('api_token', response.data.api_token);
      navigate('/administracion');
      handleLoginClick();
      setIsLoggedIn(true);
    } catch (error) {
      // Maneja los errores de la solicitud POST
      setIsSubmitting(false);
      console.error('Error:', error);
      setError('Usuario o contraseña incorrectos');
      setErrorsResponse(error.response.data);
    }
  };

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      // Cerrar el formulario y establecer isShowing en false
      setShowLogin(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef}>
      <div className="login-style" style={{ zIndex: '3000', position: 'absolute',  border: '1px solid Gainsboro' }}>
        <Formik
          validationSchema={Schema}
          initialValues={{
            email: '',
            password: '',
            grant_type: 'password',
            client_id: '2',
            client_secret: 'WWGuq45LjWKOTngQIF6OHB8MsgwEzc9GdNgoH14S',
          }}
          onSubmit={(values) => {
            const { email, password, grant_type, client_id, client_secret } = values;
            handlePost(email, password, grant_type, client_id, client_secret);
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div>
                <FloatingLabel controlId="email" label="Correo electronico" className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Correo electronico"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={(!!errors.email && touched.email) || errorsResponse.error}
                  />
                  <Form.Control.Feedback type="invalid">
                  {errors.email ? errors.email : errorsResponse.error }
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel controlId="Password" label="Clave" className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Clave"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={(!!errors.password && touched.password) || errorsResponse.error}
                  />
                  <Form.Control.Feedback type="invalid">
                  {errors.password ? errors.password : errorsResponse.error}
                  </Form.Control.Feedback>
                </FloatingLabel>
                {error && (
                  <Row className="mb-3 justify-content-center text-danger">{error}</Row>
                )}
                <Form.Control type="hidden" name="grant_type" />
                <Form.Control type="hidden" name="client_id" />
                <Form.Control type="hidden" name="client_secret" />

                <Row className="mb-3 justify-content-center">
                  ¿Olvidaste tu contraseña?
                </Row>

                <Row className="mb-3">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: '80%', marginLeft: "10%", marginTop: "10px" }}
                  >
                          {isSubmitting ? (
                        <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          <span className="visually-hidden">Enviando...</span>
                        </>
                        ) : (
                      'Continuar'
                    )}
                  </Button>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
