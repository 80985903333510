import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Products from "../../components/Products/Products";
import { useEffect } from "react";
import { useState } from "react";
import { getAllEntrepreneursWithProducts } from "../../clients/phpApp";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/funcionalities/Loading";
import ServiceUnavailable from "../../components/Exceptions/ServiceUnavailable";

const containerStyle = {
  marginLeft: "5%", // Márgen izquierdo
  marginRight: "5%", // Márgen derecho
};

function ListEntrepreneurByCategory(props) {
  const { idCategory, updateIdEmprendedor, updateIdCategory } = props;
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const entrepreneurCategory = async () => {
      setIsLoading(true);
      try {
        const dataEnrepreneurs = await getAllEntrepreneursWithProducts();
        const entrepreneursByCategory = [];

        dataEnrepreneurs.forEach((entrepreneur) => {
          if (entrepreneur.category_id.toString() === idCategory.toString()) {
            entrepreneursByCategory.push(entrepreneur);
          }
        });
        setEntrepreneurs(entrepreneursByCategory);
        setError(false)
      } catch (error) {
        setError(true)
      }
    };
    entrepreneurCategory();
    setIsLoading(false);
  }, [idCategory]);

  function newCategoryId(id) {
    updateIdCategory(id);
  }

  function visitProfileEntrepreneur(id) {
    updateIdEmprendedor(id);
    navigate(`/emprendedor/${id}`);
  }

  return (
    <Container fluid>
      {isLoading ? (
        // Mostrar mensaje de carga mientras se están recuperando los datos
        <Loading />
      ) : error ? (
        // Mostrar componente ServiceUnavailable en caso de error
        <ServiceUnavailable />
      ) : (
        <Row style={containerStyle}>
          <Col>
            <Products
              entrepreneurs={entrepreneurs}
              idCategory={idCategory}
              sendIdCategory={newCategoryId}
              showButtonCategory={true}
              showButtonVisitProfile={true}
              visitProfileEntrepreneur={visitProfileEntrepreneur}
              applySlice={true}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ListEntrepreneurByCategory;
