import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { patchChangePass } from "../../clients/phpApp";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const translateErrorMessage = (message) => {
    // Traduce los mensajes de error según sea necesario
    switch (message) {
      case "The new pass must be at least 8 characters.":
        return "La nueva contraseña debe tener al menos 8 caracteres.";
      case "The new pass format is invalid.":
        return "El formato de la nueva contraseña es inválido.";
      case "The new pass must not be greater than 16 characters.":
        return "La nueva contraseña no debe tener mas de 16 caracteres.";
      default:
        return message;
    }
  };

  const handleSubmit = async (e) => {
    //evitamos recarga
    e.preventDefault();

    // Validación del formulario a nivel de cliente
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setErrorMessage("Todos los campos son obligatorios");
      return;
    } else if (newPassword !== confirmNewPassword) {
      setErrorMessage("Las contraseñas no coinciden");
      return;
    } else {
      const passwords = {};
      passwords.actual_pass = currentPassword;
      passwords.new_pass = newPassword;
      passwords.confirm_pass = confirmNewPassword;
      try {
        await patchChangePass(passwords);
        setShowSuccessMessage(true);
        setErrorMessage(null);
      } catch (error) {
        setShowSuccessMessage(false);
        const errorJson = JSON.parse(error.response.request.response);
        // contraseñaInvalida
        if (Array.isArray(errorJson.error.new_pass)) {
          // Si hay una lista de errores
          const errorMessage = errorJson.error.new_pass.map(
            (message, index) => <p key={index}>{translateErrorMessage(message)}</p>
          );
          setErrorMessage(errorMessage);
        } else {
          // Si no es una lista
          setErrorMessage(errorJson.error);
        }
      }
    }
  };

  const formGroupStyle = { width: "35%" };

  return (
    <div className="mt-5">
      <h2>Cambio de Contraseña</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="currentPassword" style={formGroupStyle}>
          <Form.Label>Contraseña Actual</Form.Label>
          <Form.Control
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Este campo es obligatorio.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="newPassword" style={formGroupStyle}>
          <Form.Label>Nueva Contraseña</Form.Label>
          <Form.Control
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Este campo es obligatorio.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="confirmNewPassword" style={formGroupStyle}>
          <Form.Label>Confirmar Nueva Contraseña</Form.Label>
          <Form.Control
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Este campo es obligatorio.
          </Form.Control.Feedback>
        </Form.Group>

        <Button className="mt-2" variant="primary" type="submit">
          Cambiar Contraseña
        </Button>
      </Form>

      {showSuccessMessage && (
        <Alert variant="success" className="mt-3">
          Contraseña cambiada exitosamente.
        </Alert>
      )}
      {/* Mostrar la alerta si hay un mensaje de desactivación */}
      {errorMessage && (
        <Alert variant="danger" className="mt-3">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}

export default ChangePassword;
