import React from "react";
import Card from 'react-bootstrap/Card';
import "./styles/CardProduct.css"
import Typography from "../Typography";


function CardProduct({image, title, description, price, entrepreneur, id, onProductClick }) {

  function handleClick() {
    onProductClick({ image, title, description, price, entrepreneur, id, onProductClick })
  }

    return (
      <Card className="hand-cursor border-0 obscure" style={{marginBottom:"16px"}} onClick={handleClick}>
        <Card.Img variant="top" src={image[0]} className="image-style"/>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title><Typography text={title} ></Typography></Card.Title>
            <Card.Text><Typography text={"$ " + price}></Typography></Card.Text>
          </div>
        </Card.Body>
      </Card>
    );
}

export default CardProduct