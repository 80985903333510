import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout, logoutAdmin } from "../../clients/phpApp";
import "./styles/Header.css";

function Header(props) {
  const {
    handleLoginClick,
    isLoggedIn,
    setIsLoggedIn,
    isLoggedInAdmin,
    setIsLoggedInAdmin,
  } = props;
  const navigate = useNavigate();

  const underStyle = {
    fontWeight: "bold",
    textDecoration: "none",
    color: "black",
  };

  const uStyle = {
    fontWeight: "lighter",
    color: "white",
    WebkitTextStroke: "1.2px black",
    textDecoration: "none",
  };

  const userIconContainer = {
    display: "flex",
    alignItems: "center", // Centrar verticalmente
    cursor: "pointer",
  };

  const manito = {
    cursor: "pointer",
    marginTop: "-3%"
  };

  const emprendedorButtonStyle = {
    border: "1px solid black",
    color: "black",
    padding: "2px 16px", // Ajusta el padding según tu preferencia
    borderRadius: "3px",
    marginLeft: "5px",
    fontSize: "16px", // Ajusta el tamaño de la fuente según tu preferencia
    textDecoration: "none",
    fontWeight: "500",
    marginRight: "22px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  const emprendedorButtonStyle2 = {
    background: "black",
    color: "white",
    padding: "2px 16px", // Ajusta el padding según tu preferencia
    paddingTop: "16px", // Ajusta el padding según tu preferencia
    borderRadius: "3px",
    marginLeft: "5px",
    fontSize: "16px", // Ajusta el tamaño de la fuente según tu preferencia
    textDecoration: "none",
    fontWeight: "500",
    marginRight: "22px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "30px",
  };

  const handleLogout = () => {
    if (isLoggedIn) {
      logout()
        .then((response) => {
          if (response.data === "Sesion cerrada") {
            localStorage.removeItem("api_token");
            setIsLoggedIn(false);
            navigate("/");
          } else {
            localStorage.removeItem("api_token");
            setIsLoggedIn(false);
            throw new Error("Error: Sesión no cerrada");
          }
        })
        .catch((error) => {
          localStorage.removeItem("api_token");
          setIsLoggedIn(false);
          throw new Error("Error: Sesión no cerrada");
        });
    } else {
      logoutAdmin()
        .then((response) => {
          if (response.data) {
            localStorage.removeItem("api_token_admin");
            setIsLoggedInAdmin(false);
            navigate("/");
          } else {
            localStorage.removeItem("api_token_admin");
            setIsLoggedInAdmin(false);
            throw new Error("Error: Sesión no cerrada");
          }
        })
        .catch((error) => {
          localStorage.removeItem("api_token_admin");
          setIsLoggedInAdmin(false);
          throw new Error("Error: Sesión no cerrada");
        });
    }
  };

  return (
    <nav className="header d-flex">
      <div className="container-fluid d-none d-md-flex d-lg-flex  justify-content-around align-items-center">
        <div className="u-icon d-none d-md-block" style={uStyle}>
          <NavLink to="/" >
            <img src="https://firebasestorage.googleapis.com/v0/b/canje-de7e9.appspot.com/o/Union%20(7).png?alt=media&token=430eb109-5fb9-4cf9-b098-a79e44a92116" alt="" style={{ height: "30px", width: "30px" }} />
          </NavLink>
        </div>
        <div className="logo">
          <NavLink to="/" style={underStyle}>
            <span style={underStyle}>denicho</span>
          </NavLink>
        </div>
        <div className="btn-container">
          {isLoggedIn || isLoggedInAdmin ? (
            <NavLink to={isLoggedIn ? "/administracion" : "/admin"} style={emprendedorButtonStyle}>
              Ir a mi perfil
            </NavLink>
          ) : (
            <NavLink to="/registro" style={emprendedorButtonStyle}>
              Registrarse
            </NavLink>
          )}
          <div
            onClick={isLoggedIn || isLoggedInAdmin ? handleLogout : handleLoginClick}
          >
            {isLoggedIn || isLoggedInAdmin ? (
              <div style={emprendedorButtonStyle2}>
                <p>Logout</p>
              </div>
            ) : (
              <>
                <div style={emprendedorButtonStyle2}>
                  <p>Login</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
