import React from "react";
import { useNavigate } from 'react-router-dom';
import CardEntrepreneurs from "../../components/Cards/cardEntrepreneurs";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import "./styles/Home.css"
import { Container } from "react-bootstrap";
import { getLimitEntrepreneursByCategory } from "../../clients/phpApp";
import { useState, useEffect } from "react";
import catModa from "../../assets/imagen_categorias/moda-01.jpg"
import catHomeDeco from "../../assets/imagen_categorias/homedoeco-01.jpg"
import catArte from "../../assets/imagen_categorias/arte-01.jpg"
import ServiceUnavailable from "../../components/Exceptions/ServiceUnavailable";
import BannerSection from "../../components/Banner/Banner";

function Home(props) {
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { updateIdEmprendedor, updateIdCategory } = props;
  const navigate = useNavigate();

  const imagenes = { 1: catArte, 2: catHomeDeco, 3: catModa }

  useEffect(() => {
    const entrepreneursCategory = async () => {
      setIsLoading(true);
      try {
        const entrepreneurWithCategory1 = await getLimitEntrepreneursByCategory(1, 3);
        const entrepreneurWithCategory2 = await getLimitEntrepreneursByCategory(2, 3);
        const entrepreneurWithCategory3 = await getLimitEntrepreneursByCategory(3, 3);

        if (!entrepreneurWithCategory1 || !entrepreneurWithCategory2 || !entrepreneurWithCategory3) {
          throw new Error("Error fetching entrepreneurs. Response not successful.");
        }

        setEntrepreneurs([
          { category: 1, categoryName: 'Arte', entrepreneurs: entrepreneurWithCategory1 },
          { category: 2, categoryName: 'Home & Deco', entrepreneurs: entrepreneurWithCategory2 },
          { category: 3, categoryName: 'Moda', entrepreneurs: entrepreneurWithCategory3 }
        ]);
      } catch (error) {
        console.error("Error al solicitar categorías y clientes", error);
        setIsLoading(false);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    entrepreneursCategory();
  }, []);

  function showEntrepreneurById({ id }) {
    navigate(`/emprendedor/${id}`);
  }

  function showEntrepreneurByCategory(idCategory) {
    if (entrepreneurs.length > 0) {
      updateIdCategory(idCategory)
      navigate('/categorias')
    } else {
      console.error("No hay datos de categoría disponibles para mostrar emprendedores.");
    }
  }

  return (
    <>
      <BannerSection></BannerSection>
      <Container style={{ overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}>
        {isLoading && (<p>Cargando...</p>)}
        {hasError && <ServiceUnavailable />}
        {!isLoading && !hasError && entrepreneurs.map((category, idx) => (
          <div className="margin-bottom" key={idx}>

            <p className="category-title">{category.categoryName}</p>

            <div className='gallery-container '>
              {category.entrepreneurs.map((entrepreneur) => (
                <CardEntrepreneurs
                  key={entrepreneur.id}
                  entrepreneur={entrepreneur.name}
                  logo={entrepreneur.logo}
                  categoria={entrepreneur.category.name}
                  id={entrepreneur.id}
                  onLogoClick={showEntrepreneurById}
                  showFooter={false}
                />
              ))}
            </div>

          </div>
        ))}
      </Container>



    </>
  )
}

export default Home;
