import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import './styles/ModalProduct.css'
import Typography from "../Typography";


function ModalProduct({ content, onClose, show, showButtonVisitProfile, visitProfileEntrepreneur }) {
  
  function handleClick () {
      onClose(false)
  }

  function handleVisitProfileEntrepreneur(id) {
    visitProfileEntrepreneur(id)
  }

  const { title, image, description, price, entrepreneur, id } = content;
  return (
    <div className="modal show position" style={{ display: 'block'}} id='modalProduct'>
      <Modal show={show} onHide={onClose} backdrop="false" keyboard={true}>
      <Modal.Dialog>
        <Modal.Body>
        <Carousel>
          {image.map((img, index) => (
            <Carousel.Item key={index}>
            <img
                className="d-block w-100 img-modal"
                src={img}
                alt={`Slide`}/>
            </Carousel.Item>
            ))}
          </Carousel>
          <div className='body-modal'>
            <div className='title-price'>
              <Typography text={title} as="h2"></Typography>
              <Typography text={"$" + price} as="h2"></Typography>
            </div>
            <Typography text={description}></Typography>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClick}>
            <Typography text={"Cerrar"} style={{color: "white"}}></Typography>
          </Button>
          { showButtonVisitProfile && <Button variant="primary" onClick={() => handleVisitProfileEntrepreneur(id)}>
              <Typography text={"Visitar " + entrepreneur} style={{color: "white"}}></Typography>
          </Button> }
        </Modal.Footer>
      </Modal.Dialog>
      </Modal>
    </div>
  );
}

export default ModalProduct;