import { Modal, Button } from "react-bootstrap";
import {useNavigate} from "react-router-dom"

const ModalSuccess = (props) => {
  const { showModalSuccess, closedModalSuccess } = props;

  const navigate = useNavigate();

  const handleSuccess = () => {
    closedModalSuccess()
    navigate('/');
  }

  return (
    <Modal show={showModalSuccess} onHide={closedModalSuccess}>
      <Modal.Header className="justify-content-center">
        <Modal.Title>Datos enviados con exito.</Modal.Title>
      </Modal.Header>
      <Modal.Footer className="justify-content-center">
        <Button onClick={handleSuccess}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSuccess;