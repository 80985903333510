import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Alert } from 'react-bootstrap';

const NotEntrepreneurSelected = () => {
  const [redirectCountdown, setRedirectCountdown] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTimeout = setInterval(() => {
      setRedirectCountdown((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(redirectTimeout);
  }, []);

  useEffect(() => {
    if (redirectCountdown === 0) {
      navigate('/');
    }
  }, [redirectCountdown, navigate]);

  return (
    <Container className="mt-5">
      <Alert variant="danger">
        <h1 className="text-center mb-4">
          Debe seleccionar algun emprendedor.
        </h1>
        <p className="text-center">
          Será redirigido en {redirectCountdown} segundos.
        </p>
      </Alert>
    </Container>
  );
};

export default NotEntrepreneurSelected;
