import React, { useRef, useEffect, useState } from "react";

const defaultStyles = {
  fontSize: "15px",
  fontWeight: "bold",
};

function Typography({ text, as, style }) {
  if (!as) {
    as = "span"; // Establecer "span" como valor predeterminado si as no está definido
  }

  const elementRef = useRef(null);
  const [computedStyles, setComputedStyles] = useState({});
  const computedFontFamily = "Quantico, sans-serif";
  const computedColor = "#333";

  useEffect(() => {
    const element = document.createElement(as);

    if (element) {
      if (as === "span") {
        // Aplicar los estilos predeterminados
        setComputedStyles({
          ...defaultStyles,
          fontFamily: computedFontFamily,
          color: computedColor,
        });
      } else {
        // Obtener y aplicar los estilos del elemento especificado
        const computedStyle = window.getComputedStyle(element);
        const styles = {
          fontSize: computedStyle.fontSize,
          fontWeight: computedStyle.fontWeight,
          fontFamily: computedFontFamily, // Establecer el fontFamily predeterminado
          color: computedColor, // Establecer el color predeterminado
          // Agrega más estilos si es necesario
        };
        setComputedStyles(styles);
      }
    }
  }, [as]);

  const combinedStyle = {
    ...computedStyles,
    ...style,
  };

  return React.createElement(as, { style: combinedStyle, className: "default-text-style", ref: elementRef }, text);
}

export default Typography;
