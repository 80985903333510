import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const ServiceUnavailable = () => {
  return (
    <div className="container text-center mt-5">
      <h1 className="text-danger">
        <FaExclamationCircle className="mr-2" /> En estos momentos estamos en mantenimiento
      </h1>
      <p className="text-muted">Por favor, inténtelo de nuevo más tarde.</p>
    </div>
  );
};

export default ServiceUnavailable;
