import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Products from "../../components/Products/Products";
import EntrepreneurInfo from "../../components/NavSide/EntrepreneurInfo";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ModalContact from "../../components/Modal/ModalContact";
import "./styles/EntrepreneurProfile.css";
import {
  getCategoryById,
  getEntrepreneurWithProducts,
} from "../../clients/phpApp";
import NotEntrepreneurSelected from "../../components/Exceptions/NotEntrepreneurSelected";


function EntrepreneurProfile(props) {
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [error, setError] = useState(false);
  const { idEmprendedor } = useParams();


  useEffect(() => {
    setIsLoading(true);

    if (idEmprendedor === undefined || idEmprendedor === null || idEmprendedor === "") {
      setError(true)
      return;
    }
    // Llamar a la función y manejar la promesa
    getEntrepreneurWithProducts(idEmprendedor)
      .then((data) => {
        setEntrepreneurs(data);
        getCategoryById(data[0].category_id)
          .then((categoryData) => {
            setCategory(categoryData);
          })
          .catch((categoryError) => {
            console.error(
              "Error al obtener los datos de la categoría:",
              categoryError
            );
          })
      })
      .catch((error) => {
        console.error("Error al obtener los datos del emprendedor:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [idEmprendedor]);

  function openModalMessage(isOpen) {
    setIsModal(isOpen);
  }

  function closedModalMessage() {
    setIsModal(false);
  }

  return (
    <Container fluid>
      {error ? (<NotEntrepreneurSelected />) : (
        <Row className="p-3" style={{ position: "static" }}>
          <Col md={3} className="container-info-style">
            <div >
              {isLoading ? (
                <p>Cargando...</p>
              ) : (
                <EntrepreneurInfo entrepreneurs={entrepreneurs} category={category} handleModal={openModalMessage} />
              )}
            </div>
          </Col>
          <Col md={9} className="margin-left-info">
            <Products entrepreneurs={entrepreneurs} showButtonCategory={false} showButtonVisitProfile={false} />
          </Col>
        </Row>
      )}
      {isModal && <ModalContact handleModal={closedModalMessage} show={isModal} entrepreneurs={entrepreneurs} />}
    </Container>
  );
}

export default EntrepreneurProfile;
