import React, { useEffect, useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import {Spinner} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { Container } from "react-bootstrap";
import "./styles/RegisterEntrepreneur.css";
import { postRegister } from "../../clients/phpApp";
import ModalEditImage from "../../components/Modal/ModalEditImage";
import ModalSuccess from "../../components/Modal/ModalSuccess";

function RegisterEntrepreneur({ handleShowNavHead  }) {
    const { Formik } = formik;
    const [errorsResponse, setErrorsResponse] = useState("")
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModalImage, setShowModalImage] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef(null);


  const schema = yup.object().shape({
    name: yup.string().required("El nombre del emprendedor es requerido."),
    email: yup.string().required("El correo electronico es requerido.")
    .email("El correo electrónico no tiene un formato válido.")
    .min(5, "El correo electrónico debe tener al menos 5 caracteres."),
    whatsapp: yup.string().required("El numero de whatsapp es requerido.")
    .matches(/^\d{9}$/, 'Formato incorrecto.'),
    instagram: yup.string().required("El instagram es requerido."),
    category: yup.string().required("Debes seleccionar una categoria"),
    description: yup.string().required("La descripcion es requerida."),
  });

  useEffect(() => {
    handleShowNavHead (false)

    return () => {
        handleShowNavHead (true)
    }
  }, [handleShowNavHead] )

  const registerEntrepreneur = async (name, email, whatsapp, description, category, instagram, selectedFile) => {
    setIsSubmitting(true);
    try {
        const data = {};
        data.name = name;
        data.description = description;
        data.email = email;
        data.whatsapp = whatsapp;
        data.instagram = instagram;
        data.category_id = category;
        data.logo = selectedFile;
        const response = await postRegister(data);
        setTimeout(() => {
            setIsSubmitting(false);
          }, 2000);
          
        if (response.status === 201) {
            setShowModalSuccess(true);
            setErrorsResponse("");
        } else {
            alert("Error al cargar los datos", response.error);
        }
        
    } catch(error) {
        setIsSubmitting(false);
        setErrorsResponse(error.response.data.error);
    }
  }

  const handleImageSelection = (event) => {
    try {
        const file = event.target.files[0];
        const imageUrl =  URL.createObjectURL(file);
        setSelectedFile(imageUrl)
        setShowModalImage(true)
    } catch (error) {
        return;
    }
  }

    function handleSizeImage(newImage) {
        setSelectedFile(newImage)
    } 

    function closedModalImage() {
        setShowModalImage(false);
    }

    function closedModalSuccess() {
        setShowModalSuccess(false)
    }

    function clearImage() {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }
  return (
    <Container className="custom-container">
        <div className="contenedor-register">
            <Formik
            validationSchema={schema}
            initialValues={{
                name: '',
                email: '',
                whatsapp: '',
                instagram: '',
                category: '',
                logo: null,
                description: '',
            }}
            onSubmit={(values) => {
                console.log(values);
                const { name, email, whatsapp, description, category, instagram } = values;
                registerEntrepreneur( name, email, whatsapp, description, category, instagram, selectedFile );
            }}
            >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit} >
                    <Row>
                        <Form.Group className="p-2 p-md-2 p-lg-2" >
                            <FloatingLabel controlId="name" label="Nombre del emprendimiento">
                                <Form.Control
                                    type="text"
                                    placeholder="Nombre del emprendimiento"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={(!!errors.name && touched.name) || errorsResponse.name}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.name ? errors.name : "Este emprendimiento ya se encuentra registrado" }
                                </Form.Control.Feedback>

                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="p-2 p-md-2 p-lg-2">
                            <FloatingLabel controlId="email" label="Correo electronico">
                                <Form.Control
                                    type="email"
                                    placeholder="Correo electronico"
                                    aria-describedby="inputGroupPrepend"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={(!!errors.email && touched.email) || errorsResponse.email}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.email ? errors.email : "Este correo ya se encuentra registrado"}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="p-2 p-md-2 p-lg-2" >
                            <FloatingLabel controlId="whatsapp" label="Whatsapp">
                                <Form.Control
                                    type="text"
                                    placeholder="whatsapp"
                                    name="whatsapp"
                                    value={values.whatsapp}
                                    onChange={handleChange}
                                    isInvalid={(!!errors.whatsapp && touched.whatsapp) || errorsResponse.whatsapp}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.whatsapp ? errors.whatsapp : "Este numero de whatsapp ya esta registrado"}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="p-2 p-md-2 p-lg-2">
                            <FloatingLabel controlId="instagram" label="Instagram">
                                <Form.Control
                                    type="text"
                                    placeholder="Instagram"
                                    name="instagram"
                                    value={values.instagram}
                                    onChange={handleChange}
                                    isInvalid={(!!errors.instagram && touched.instagram) || errorsResponse.instagram}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.instagram ? errors.instagram : "Esta cuenta de Instagram ya esta registrada"}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <small style={{color:"#ababab"}}>* URL completa de tu perfil, ej https://www.instagram.com/tuperfil</small>
                        <Form.Group className="p-2 p-md-2 p-lg-2">
                            <FloatingLabel controlId="category" label="Categoria">
                                <Form.Select 
                                    name="category"
                                    value={values.category}
                                    onChange={handleChange}
                                    isInvalid={touched.category && errors.category }
                                    >
                                    <option value="" >Elije una categoria</option>
                                    <option value="1">Arte</option>
                                    <option value="2">Home&Deco</option>
                                    <option value="3">Moda</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                               {errors.category}
                            </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="p-2 p-md-2 p-lg-2">
                            <FloatingLabel controlId="logo" label="Logo del emprendimiento">
                                <Form.Control
                                    type="file"
                                    required
                                    name="logo"
                                    value={values.logo}
                                    ref={fileInputRef}
                                    onClick={(event) => clearImage(event)}
                                    onChange={(event) => handleImageSelection(event)}
                                    isInvalid={(!!errors.logo && touched.logo) || errorsResponse.logo}

                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    { errors.logo ? errors.logo : "La imagen debe tener un formato .jpg, jpeg, png"}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <small style={{color:"#ababab"}}>* Tamaño óptimo 400x400px</small>
                        <Form.Group className="p-2 p-md-2 p-lg-2">
                            <FloatingLabel controlId="description" label="descripcion">
                                <Form.Control
                                    as="textarea"
                                    style={{ height: '100px' }}
                                    placeholder="descripcion"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    isInvalid={(!!errors.description && touched.description) || errorsResponse.description}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.description ? errors.description : errorsResponse.description}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                            style={{ width: '80%', marginLeft: "10%", marginTop: "10px" }}
                        >
                            {isSubmitting ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                <span className="visually-hidden">Enviando...</span>
                            </>
                            ) : (
                                'Enviar'
                            )}
                        </Button>
                    </Row>
                </Form>
            )}
            </Formik>
            </div>
            {showModalImage && <ModalEditImage
                imageUrl={selectedFile}
                showModalImage={showModalImage}
                closedModalImage={closedModalImage}
                handleImage={handleSizeImage}
            />}
            {showModalSuccess && <ModalSuccess
                showModalSuccess={showModalSuccess}
                closedModalSuccess={closedModalSuccess}
            />}
    </Container>
  );
}

export default RegisterEntrepreneur;