import React, { useState, useEffect } from "react";
import { CDBCard, CDBCardBody, CDBDataTable } from "cdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  patchActivateProduct,
  delProduct,
  getProductsAdmin,
} from "../../clients/phpApp";
import ModalConfirmDelete from "../Modal/ModalConfirmarDelete";
import ModalAddProduct from "../Modal/ModalAddProduct";
import ModalEditProduct from "../Modal/ModalEditProduct";

const DataTableProducts = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [disableMessage, setDisableMessage] = useState(null);
  const [editedProduct, setEditedProduct] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    // Llamar a la función y manejar la promesa
    getProductsAdmin()
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.error("Error al obtener los datos del emprendedor:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDisable = async (productId, productActive) => {
    try {
      const updatedProduct = await patchActivateProduct(
        productId,
        !productActive
      );
      if (updatedProduct.data.active === true) {
        updatedProduct.data.active = 1;
      } else {
        updatedProduct.data.active = 0;
      }
      setProducts((prevProducts) => {
        const updatedProducts = prevProducts.map((product) =>
          product.id === productId
            ? { ...product, active: updatedProduct.data.active }
            : product
        );

        return updatedProducts;
      });

      // Restablecer el mensaje
      setDisableMessage(null);
    } catch (error) {
      // Manejar el error y establecer el mensaje de error
      setDisableMessage(
        "No se pudo desactivar el producto. Inténtalo de nuevo."
      );
    }
  };

  const handleEdit = (productId) => {
    const selectedProduct = products.find(
      (product) => product.id === productId
    );
    setEditedProduct(selectedProduct);
    setShowEditModal(true);
  };

  const handleDelete = (productId) => {
    setShowDeleteModal(true);
    setSelectedProductId(productId);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedProductId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const updatedProduct = await delProduct(selectedProductId);

      if (updatedProduct.data.id === selectedProductId) {
        // Si el producto se ha eliminado, actualiza el estado eliminando el producto de la lista
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== selectedProductId)
        );
      }

      // Restablecer el mensaje
      setDisableMessage(null);
      // cerrar modal
      handleCloseDeleteModal();
    } catch (error) {
      // Manejar el error y establecer el mensaje de error
      setDisableMessage("No se pudo eliminar el producto. Inténtalo de nuevo.");
      handleCloseDeleteModal();
      console.error(error);
    }
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const dataTableData = () => {
    if (!products) {
      return {
        columns: [],
        rows: [],
      };
    }

    const rows = products.map((product) => {
      return {
        name: product.name,
        price: `$${parseFloat(product.price).toFixed(2)}`,
        edit: (
          <span
            onClick={() => handleEdit(product.id)}
            style={{ cursor: "pointer", color: "blue" }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />
          </span>
        ),
        delete: (
          <span
            onClick={() => handleDelete(product.id)}
            style={{ cursor: "pointer" }}
            className="text-danger"
          >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: "5px" }} />
          </span>
        ),
        disable: (
          <span
            className={`text-${product.active === 1 ? "success" : "danger"}`}
            onClick={() => handleDisable(product.id, product.active)}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon
              icon={product.active === 1 ? faEye : faEyeSlash}
              style={{ marginRight: "5px" }}
            />
          </span>
        ),
      };
    });

    return {
      columns: [
        {
          label: "Nombre",
          field: "name",
          width: 30,
        },
        {
          label: "Precio",
          field: "price",
          width: 30,
        },
        {
          label: "Editar",
          field: "edit",
          width: 30,
        },
        {
          label: "Eliminar",
          field: "delete",
          width: 30,
        },
        {
          label: "Desactivar",
          field: "disable",
          width: 30,
        },
      ],
      rows: rows,
    };
  };

  return (
    <>
      <CDBCard>
        <div
          style={{ textAlign: "right", marginBottom: "2%", marginTop: "10px" }}
        >
          <button className="btn btn-success" onClick={handleShowAddModal}>
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
            Agregar Nuevo Producto
          </button>
        </div>
        <CDBCardBody>
          <div className="tableContainer">
            {/* Mostrar la alerta si hay un mensaje de desactivación */}
            {disableMessage && (
              <Alert variant="danger" className="mt-3">
                {disableMessage}
              </Alert>
            )}
            <CDBDataTable
              striped
              bordered
              hover
              entriesOptions={[10, 20, 30, 100]}
              entries={10}
              pagesAmount={4}
              data={dataTableData()}
              materialSearch={true}
              infoLabel={["Mostrando", "a", "de", "productos"]}
              entriesLabel="Mostrar"
              searchLabel="Buscar"
              noBottomColumns
              noRecordsFoundLabel="No se encontraron registros"
            />
          </div>
        </CDBCardBody>
      </CDBCard>

      <ModalConfirmDelete
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
      ></ModalConfirmDelete>

      <ModalAddProduct showAddModal={showAddModal} setDisableMessage={setDisableMessage} setShowAddModal={setShowAddModal} setProducts={setProducts}></ModalAddProduct>
        
      <ModalEditProduct showEditModal={showEditModal} setEditedProduct={setEditedProduct} editedProduct={editedProduct} setDisableMessage={setDisableMessage}  setShowEditModal={setShowEditModal} setProducts={setProducts}></ModalEditProduct>
    </>
  );
};

export default DataTableProducts;
