import React, { useState, useEffect } from 'react';
import './Banner.css';

const BannerSection = () => {
    const [currentImage, setCurrentImage] = useState(0);

    // Array of image URLs


    // Array of image URLs and corresponding titles
    const images = [
        { url: 'https://www.pictureframesexpress.co.uk/blog/wp-content/uploads/2020/05/7-Tips-to-Finding-Art-Inspiration-Header-1024x649.jpg', title: 'Arte' },
        { url: 'https://audaces.com/wp-content/uploads/2023/08/7-estilos-de-moda-universais-1024x683.jpg.webp', title: 'Moda' },
        { url: 'https://www.hogarmania.com/archivos/202103/ideas-decorar-cestas-mimbre-848x477x80xX.jpg', title: 'Home & Deco' },
        // Add more image URLs and titles as needed
    ];

    const changeImage = (increment) => {
        setCurrentImage((prevImage) => (prevImage + increment + images.length) % images.length);
    };

    useEffect(() => {
        const intervalId = setInterval(() => changeImage(1), 3000);

        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures that the effect runs only once on mount

    return (
        <div className="banner-section" style={{ height: '80vh', overflow: 'hidden', position: 'relative' }}>
            <div
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
                }}
            ></div>

            <img
                src={images[currentImage].url}
                alt={`Banner ${currentImage + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />

            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: 'white',
                }}
            >
                <h2
                    style={{
                        fontSize: "100px",
                        opacity: "80%"
                    }}>{images[currentImage].title}</h2>
            </div>

            <button
                onClick={() => changeImage(-1)}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    transform: 'translateY(-50%)',
                    background: 'transparent',
                    border: 'none',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                    color: 'white',
                }}
            >
                {'<'}
            </button>

            <button
                onClick={() => changeImage(1)}
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    background: 'transparent',
                    border: 'none',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                    color: 'white',
                }}
            >
                {'>'}
            </button>
        </div>
    );
};

export default BannerSection;