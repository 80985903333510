import { Spinner } from "react-bootstrap";

function Loading() {
  return (
    <div className="text-center mt-5">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Cargando...</span>
      </Spinner>
      <p className="mt-2">Cargando...</p>
    </div>
  );
}

export default Loading;
