import React from "react";
import Card from 'react-bootstrap/Card';
import "./styles/CardEntrepreneurs.css"
import Typography from "../Typography";
import { baseUrlImgs } from '../../parametria'

function CardEntrepreneurs({ entrepreneur, logo, id, onLogoClick, categoria, showFooter }) {

  function handleClick() {
    onLogoClick({ id })
  }

  return (
    <div className="card-entrepreneur obscure" onClick={handleClick}>
      <Card.Img
        variant="top"
        src={baseUrlImgs + logo}
        className="imagen-style-entrepreneur"
      />

      {/* <div className="centered-text">
        <Typography
          text={entrepreneur}
          style={{
            whiteSpace: 'nowrap',
            fontSize: `${entrepreneur && entrepreneur.length > 20 ? '15px' : '20px'}`,
            color: "black",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div> */}
    </div>
  );
}

export default CardEntrepreneurs;
