import React from "react";
import { Container } from "react-bootstrap";
import Products from "../../components/Products/Products";
import ServiceUnavailable from "../../components/Exceptions/ServiceUnavailable"; // Importa el componente ServiceUnavailable
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { getSearchProduct } from "../../clients/phpApp";
import Typography from "../../components/Typography";
import Loading from "../../components/funcionalities/Loading";

function ProductsSearch(props) {
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  // Utiliza useLocation para obtener la ubicación actual
  const location = useLocation();
  // Extrae searchTerm de la cadena de consulta
  const searchTerm = new URLSearchParams(location.search).get('term');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const data = await getSearchProduct(searchTerm);
        const response = [];
        response[0] = {};
        response[0]['products'] = data.data;
        setEntrepreneurs(response);
      } catch (error) {
        console.error("Error al buscar productos", error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Llama a fetchData cuando cambia searchTerm
    fetchData();
  }, [searchTerm]); 

  return (
    <Container fluid>
      {isLoading ? (
        // Mostrar mensaje de carga mientras se están recuperando los datos
        <Loading/>
      ) : error ? (
        // Mostrar componente ServiceUnavailable en caso de error
        <ServiceUnavailable />
      ) : (
        // Mostrar productos cuando la carga haya terminado y no haya error
        <>
        <Typography text={"Mostrando resultado para: '" + searchTerm + "'"} as="h2"/>
        <Products entrepreneurs={entrepreneurs} showButtonCategory={false} showButtonVisitProfile={false} searching={true}/>
        </>
      )}
    </Container>
  );
}

export default ProductsSearch;
