import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Typography from "../Typography";
import { Container } from "react-bootstrap";
{/*s*/}

//Comentario para ver si identifica cambio mayuscula
function SearchContainer() {
    const [searchOpen, setSearchOpen] = useState(false);

    const handleSearchClick = () => {
      setSearchOpen(!searchOpen);
    };

  return (
    <Container className="d-flex d-md-none d-lg-none" style={{marginBottom: "5%", width: "auto"}}>
    <FloatingLabel
      controlId="floatingInputGrid"
      label={<Typography text={"Buscar emprendedor"} style={{ fontWeight: "normal", color: "silver" }}></Typography>}
      className="d-flex align-items-center"
      style={{ width: "100%" }}
    >
      <Form.Control
        type="email"
        className="search-input"
        placeholder=""
        style={{
          border: "none",
          width: "100%",
        }}
      />
      <InputGroup.Text
        style={{
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
        onClick={handleSearchClick}
      >
        <FontAwesomeIcon
          icon={faSearch}
          style={{
            color: searchOpen ? "blue" : "#000",
          }}
        />
      </InputGroup.Text>
    </FloatingLabel>
    </Container>
  );
}

export default SearchContainer;
