import React from "react";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import { useState, useEffect } from "react";
import ModalEditImage from "./components/Modal/ModalEditImage.jsx"
import Header from "./components/NavBars/Header";
import NavBar from "./components/NavBars/NavBar";
import EntrepreneursList from "./container/Entrepreneur/EntrepreneursList";
import ListEntrepreneurByCategory from "./container/Entrepreneur/ListEntrepreneurByCategory";
import EntrepreneurProfile from "./container/Entrepreneur/EntrepreneurProfile";
import PanelAdministration from "./container/PanelAdministration/PanelAdministration"
import Home from "./container/Home/Home";
import LoginAdmin from "./components/Login/LoginAdmin";
import SearchContainer from "./components/funcionalities/SearchContainer";
import "./App.css";
import RegisterEntrepreneur from "./container/Entrepreneur/RegisterEnrepreneur";
import DataTableEntrepreneurs from "./components/Administration/DataTableEntrepreneurs.jsx";
import ProductsSearch from "./container/Search/ProductsSearch.jsx"
import { getCheckApiTokenIsValid, getCheckApiTokenAdminIsValid } from "./clients/phpApp";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("api_token") !== null);
  const [idEmprendedor, setIdEmprendedor] = useState("");
  const [showHeaderNav, setShowHeaderNav] = useState(true);
  const [isAdminPanelActive, setIsAdminPanelActive] = useState(false);
  const [isShowLogin, setShowLogin] = useState(false);
  const [idCategory, setIdCategory] = useState("1");
  const [isLoggedInAdmin, setIsLoggedInAdmin] = useState(localStorage.getItem("api_token_admin") !== null);

  
  useEffect(() => {
    // Verificar el token de usuario al montar el componente
    const verifyUserToken = async () => {
      if (localStorage.getItem('api_token') === undefined) {
        setIsLoggedIn(false);
        return
      }
      try {
        const responseUser = await getCheckApiTokenIsValid("api_token");

        // Actualizar el estado isLoggedIn en función de si el token de usuario está presente
        setIsLoggedIn(responseUser !== undefined);

        // Si el token de usuario es nulo, eliminarlo
        if (!responseUser) {
          localStorage.removeItem("api_token");
        }
      } catch (error) {

        // En caso de error, eliminar el token de usuario
        localStorage.removeItem("api_token");

        // Establecer el estado como no autenticado
        setIsLoggedIn(false);
      }
    };

    // Verificar el token de administrador al montar el componente
    const verifyAdminToken = async () => {
      if (localStorage.getItem('api_token_admin') === undefined) {
        setIsLoggedInAdmin(false);
        return
      }
      try {
        const responseAdmin = await getCheckApiTokenAdminIsValid("api_token_admin")

        setIsLoggedInAdmin(responseAdmin !== undefined);
        // Si el token de administrador es nulo, eliminarlo
        if (!responseAdmin) {
          localStorage.removeItem("api_token_admin");
        } 
      } catch (error) {

        // En caso de error, eliminar el token de administrador
        localStorage.removeItem("api_token_admin");

        // Establecer el estado como no autenticado para admin
        setIsLoggedInAdmin(false);
      }
    };

    verifyUserToken();
    verifyAdminToken();
  }, []);

  const updateIdCategory = (id) => {
    setIdCategory(id);
  };

  const updateIdEmprendedor = (id) => {
    setIdEmprendedor(id);
  };

  const toggleAdminPanel = (boolean) => {
    setIsAdminPanelActive(boolean);
  };
  
  const handleLoginClick = () => {
    setShowLogin(!isShowLogin);
  };

  const handleShowNavHead = (show) => {
    setShowHeaderNav(show)
  }

  return (
    <Router>
      {showHeaderNav && (
      <>
        <Header handleLoginClick={handleLoginClick} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} isLoggedInAdmin={isLoggedInAdmin} setIsLoggedInAdmin={setIsLoggedInAdmin}/>
        <NavBar 
          isShowLogin={isShowLogin}
          handleLoginClick={handleLoginClick}
          isLoggedIn={isLoggedIn}
          isLoggedInAdmin={isLoggedInAdmin}
          setIsLoggedIn={setIsLoggedIn}
          setIsLoggedInAdmin={setIsLoggedInAdmin}
          setShowLogin={setShowLogin}
          updateIdCategory={updateIdCategory} />
      </>
      )}

      <div className={`${isAdminPanelActive ? 'admin-panel-active' : 'admin-panel-inactive'}`}>
          {isAdminPanelActive  ? (
            <></>
          ) : (
            <SearchContainer />
          )}
        <Routes>
        <Route path="image" element={<ModalEditImage />} />
          <Route path="/" element={<Home updateIdEmprendedor={updateIdEmprendedor} updateIdCategory={updateIdCategory}/>} />
          <Route path="/emprendedor/:idEmprendedor" element={<EntrepreneurProfile idEmprendedor={idEmprendedor} />} />
          <Route path="/emprendedores" element={<EntrepreneursList idCategory={idCategory} updateIdEmprendedor={updateIdEmprendedor}/>} />
          <Route path="/categorias" element={<ListEntrepreneurByCategory idCategory={idCategory} updateIdEmprendedor={updateIdEmprendedor} updateIdCategory={updateIdCategory}/>} />
          <Route path="/administracion" element={<PanelAdministration toggleAdminPanel={toggleAdminPanel} setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/busqueda" element={<ProductsSearch/>} />
          <Route path="*" element={<h1 style={{ marginTop: "10%" }}> Not Found</h1>} />
          <Route path="/registro" element={<RegisterEntrepreneur handleShowNavHead={handleShowNavHead} />} />
          <Route path="/login" element={<LoginAdmin handleShowNavHead={handleShowNavHead} setIsLoggedInAdmin={setIsLoggedInAdmin}/>} />
          <Route path="/admin" element={<DataTableEntrepreneurs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
