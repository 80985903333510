import React, { useEffect,useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { Container, Spinner } from "react-bootstrap";
import "./styles/LoginAdmin.css";
import { postLoginAdmin } from "../../clients/phpApp";
import { useNavigate  } from 'react-router-dom'

function LoginAdmin(props) {
    const { handleShowNavHead, setIsLoggedInAdmin } = props;
    const navigate = useNavigate();
    const { Formik } = formik;
    const [errorsResponse, setErrorsResponse] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required("El correo electronico es requerido."),
    password: yup.string().required("Ingrese contraseña."),
  });

  useEffect(() => {
    handleShowNavHead(false)

    return () => {
        handleShowNavHead(true)
    }
  }, [handleShowNavHead])

  const registerEntrepreneur = async ( email, password) => {
    setIsSubmitting(true);
    try {
        const data = {};
        data.email = email;
        data.password = password;
        const response = await postLoginAdmin(data);
        setTimeout(() => {
            setIsSubmitting(false);
          }, 2000);

        if (response.data) {
            localStorage.setItem('api_token_admin', response.data.api_token_admins);
            setIsLoggedInAdmin(true);
            navigate('/admin')
          } else {
            alert('Credenciales incorrectas. Intente nuevamente.');
        }
    } catch(error) {
        setIsSubmitting(false);
        setErrorsResponse(error.response.data);
        console.log(error.response.data)
    }
  }

  return (
    <Container className="custom-container-login">
        <div className="contenedor-login-admin">
            <Formik
            validationSchema={schema}
            initialValues={{
                email: '',
                password: '',
            }}
            onSubmit={(values) => {
                const { email, password } = values;
                registerEntrepreneur( email, password );
            }}
            >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit} >
                    <Row>
                        <Form.Group className="p-2 p-md-2 p-lg-2">
                            <FloatingLabel controlId="email" label="Correo electronico">
                                <Form.Control
                                    type="email"
                                    placeholder="Correo electronico"
                                    aria-describedby="inputGroupPrepend"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={(!!errors.email && touched.email) || errorsResponse.error}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.email ? errors.email : errorsResponse.error }
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="p-2 p-md-2 p-lg-2" >
                            <FloatingLabel controlId="password" label="Clave">
                                <Form.Control
                                    type="password"
                                    placeholder="Clave"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={(!!errors.password && touched.password) || errorsResponse.error}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                {errors.password ? errors.password : errorsResponse.error }
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                            style={{ width: '80%', marginLeft: "10%", marginTop: "10px" }}
                        >
                            {isSubmitting ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                <span className="visually-hidden">Enviando...</span>
                            </>
                            ) : (
                                'Enviar'
                            )}
                        </Button>
                    </Row>
                </Form>
            )}
            </Formik>
            </div>
    </Container>
  );
}

export default LoginAdmin;