import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
import './styles/ModalContact.css'
import * as formik from 'formik';
import * as yup from 'yup';
import { postSendMessage } from '../../clients/phpApp';
import { useState } from 'react';

function ModalContact({handleModal, show, entrepreneurs}) {
  const { Formik } = formik;
  const [errorMessage, setErrorMessage] = useState(null);

  const translateErrorMessage = (message) => {
    // Traduce los mensajes de error según sea necesario para cada campo
    switch (message) {
      case "The email must be a valid email address.":
        return "El email debe ser una dirección de correo electrónico válida.";
      case "The whatsapp format is invalid.":
        return "El formato de WhatsApp no es inválido.";
      case "The whatsapp must not be greater than 9 characters.":
       return "El campo whatsapp no debe contener mas de 9 caracteres";
      default:
        return message;
    }
  };

  const schema = yup.object().shape({
    name: yup.string().required("El campo nombre completo es requerido"),
    email: yup.string().email("El campo email debe ser un correo electrónico válido").required("El campo email es requerido"),
    whatsapp: yup.string().matches(/^[0-9]{9}$/, "El campo WhatsApp debe ser un numero valido").required("El campo WhatsApp es requerido"),
    message: yup.string().required("El campo mensaje es requerido"),
  });

  function handleClick() {
    handleModal(false);
  }

  async function handleSubmitForm(values, { setSubmitting }) {
    // Aquí es donde manejas la lógica de envío del formulario
    // Puedes hacer una llamada a una API, enviar datos al servidor, etc.
    setSubmitting(true)

    try {
        const response = await postSendMessage(entrepreneurs[0].id, values)
        handleModal(false);
        console.log(response)
    } catch (error) {
        const errorJson = JSON.parse(error.response.request.response);
        console.log(errorJson.error);
    
        const errorMessages = Object.keys(errorJson.error).map(field => {
            const fieldErrors = errorJson.error[field];
            return (
              <li key={field}>
                <strong>{field}:</strong>
                <ul>
                  {fieldErrors.map((message, index) => (
                    <li key={`${field}_${index}`}>{translateErrorMessage(message)}</li>
                  ))}
                </ul>
              </li>
            );
          });
        console.log("error messages", errorMessages)
    
          setErrorMessage(errorMessages);
      }
  
    // Importante: Marcar el formulario como no "submitting" después de manejar la lógica.
    setSubmitting(false);
  }

  return (
    <div className="modal show position" style={{ display: 'block' }} >
        <Modal show={show} onHide={handleModal} backdrop="false" keyboard={true}>
        <Modal.Body>
            <Formik
                validationSchema={schema}
                onSubmit={handleSubmitForm}
                initialValues={{
                    name: '',
                    email: '',
                    whatsapp: '',
                    message: '',
                }}
            >
            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Contactanos</Modal.Title>
                    </Modal.Header>
                    <div className='margin-form'>
                    <Modal.Body>
                        <Form.Group className="mb-3" >
                            <FloatingLabel controlId="name" label="Nombre completo">
                                <Form.Control
                                    type="text"
                                    placeholder="Nombre completo"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name && touched.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <FloatingLabel label="Correo electronico" controlId="email">
                                <Form.Control
                                    type="email"
                                    placeholder="Correo electronico"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email && touched.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group  className="mb-3">
                            <FloatingLabel label="WhatsApp" controlId="whatsapp">  
                                <Form.Control
                                    type="text"
                                    placeholder="WhatsApp"
                                    name="whatsapp"
                                    value={values.whatsapp}
                                    onChange={handleChange}
                                    isInvalid={!!errors.whatsapp && touched.whatsapp}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.whatsapp}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <FloatingLabel label="Mensaje" controlId="message"> 
                            <Form.Control
                                as="textarea"
                                style={{ height: '100px' }}
                                placeholder="Mensaje"
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                isInvalid={!!errors.message && touched.message}
                            />
                        <Form.Control.Feedback type="invalid">
                            {errors.message}
                        </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    {errorMessage && (
  <div className="alert alert-danger" role="alert">
    <ul>
      {errorMessage}
    </ul>
  </div>
)}
                    </Modal.Body>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClick}>Cerrar</Button>
                                          <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        {' Enviando...'}
                      </>
                    ) : (
                      'Enviar'
                    )}
                  </Button>
                    </Modal.Footer>
                </Form>
            )}
            </Formik>
          </Modal.Body>
        </Modal>
    </div>
  );
}

export default ModalContact;