import React, { useEffect, useState } from "react";
import CardProduct from "../Cards/CardProduct";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles/Products.css";
import { Button, Container } from "react-bootstrap";
import Typography from "../Typography";
import ModalProduct from "../Modal/ModalProduct";
import { baseUrlImgs } from "../../parametria";
import noImage from "../../assets/no-image.jpeg";

function Products(props) {
  const {
    entrepreneurs,
    sendIdCategory,
    idCategory,
    showButtonCategory,
    showButtonVisitProfile,
    visitProfileEntrepreneur,
    applySlice,
    searching,
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [category, setCategory] = useState(idCategory);
  const categories = { 1: "Arte", 2: "Home&Deco", 3: "Moda" };
  const [buttonCategories, setButtonCategories] = useState("");

  useEffect(() => {
    const updatedButtonCategories = { ...categories };
    delete updatedButtonCategories[idCategory];
    setCategory(idCategory); // Actualiza la categoría en el estado
    setButtonCategories(updatedButtonCategories);
  }, [idCategory]);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function changeCategory(newCategoryId) {
    setCategory(newCategoryId);
    sendIdCategory(newCategoryId);
  }

  return (
    <Container className="productsStyle">
      {showButtonCategory && (
        <Row>
          <div className="d-flex align-items-center justify-content-between category-title">
            <Col>
              <Typography as={"h2"} text={categories[category]} />
            </Col>
          </div>
        </Row>)}
      {entrepreneurs.map((entrepreneur) => (
        <Row key={entrepreneur.name}>
          <div className="d-flex justify-content-between align-items-center entrepeneur-name">
            {entrepreneurs.length > 0 && <Typography text={entrepreneur.name} as="h4" />}
          </div>
          {entrepreneur.products.slice(0, 4).map((product) => (
            <Col key={product.id} xs={12} md={4} lg={3}>
              <CardProduct
                entrepreneur={entrepreneur.name}
                id={entrepreneur.id}
                image={[
                  baseUrlImgs + product.image1,
                  product.image2 !== "none" ? baseUrlImgs + product.image2 : null,
                  product.image3 !== "none" ? baseUrlImgs + product.image3 : null,
                ]}
                title={product.name}
                description={product.description}
                price={product.price}
                onProductClick={handleOpenModal}
              />
            </Col>
          ))}
        </Row>
      ))}

      {isModalOpen && (
        <ModalProduct
          onClose={handleCloseModal}
          content={modalContent}
          show={isModalOpen}
          showButtonVisitProfile={showButtonVisitProfile}
          visitProfileEntrepreneur={visitProfileEntrepreneur}
        />
      )}
    </Container>
  );
}

export default Products;