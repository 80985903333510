import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons"; // Importa el ícono de la bolsa de compras
import "./styles/NavBar.css"; // Archivo de estilos personalizados
import Typography from "../Typography";
import Search from "../funcionalities/Search";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Login from "../Login/Login";
import { logout, logoutAdmin } from "../../clients/phpApp";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCategories } from "../../clients/phpApp";

function NavBar(props) {
  const {
    isShowLogin,
    handleLoginClick,
    isLoggedIn,
    setIsLoggedIn,
    setIsLoggedInAdmin,
    isLoggedInAdmin,
    setShowLogin,
    updateIdCategory,
  } = props;
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const underStyle = {
    fontWeight: "bold",

  };



  const handleLogout = () => {
    if (isLoggedIn) {
      logout()
        .then((response) => {
          if (response.data === "Sesion cerrada") {
            localStorage.removeItem("api_token");
            setIsLoggedIn(false);
            navigate("/");
          } else {
            localStorage.removeItem("api_token");
            setIsLoggedIn(false);
            throw new Error("Error: Sesión no cerrada");
          }
        })
        .catch((error) => {
          localStorage.removeItem("api_token");
          setIsLoggedIn(false);
          throw new Error("Error: Sesión no cerrada");
        });
    } else {
      logoutAdmin()
        .then((response) => {
          if (response.data) {
            localStorage.removeItem("api_token_admin");
            setIsLoggedInAdmin(false);
            navigate("/");
          } else {
            localStorage.removeItem("api_token_admin");
            setIsLoggedInAdmin(false);
            throw new Error("Error: Sesión no cerrada");
          }
        })
        .catch((error) => {
          localStorage.removeItem("api_token_admin");
          setIsLoggedInAdmin(false);
          throw new Error("Error: Sesión no cerrada");
        });
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const showEntrepreneurById = (id) => {
    updateIdCategory(id);
    navigate("/categorias");
  };

  const manito = {
    cursor: "pointer",
    marginTop: "-4%",
  };

  const emprendedorButtonStyle = {
    border: "1px solid black",
    color: "black",
    padding: "2px 16px", // Ajusta el padding según tu preferencia
    borderRadius: "3px",
    marginLeft: "5px",
    fontSize: "16px", // Ajusta el tamaño de la fuente según tu preferencia
    textDecoration: "none",
    fontWeight: "500",
    marginRight: "22px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  const emprendedorButtonStyle3 = {
    background: "black",
    color: "white",
    paddingTop: "12px",
    padding: "2px 16px", // Ajusta el padding según tu preferencia
    borderRadius: "3px",
    marginLeft: "5px",
    fontSize: "16px", // Ajusta el tamaño de la fuente según tu preferencia
    textDecoration: "none",
    fontWeight: "500",
    marginRight: "22px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "30px",
  };

  return (
    <>
      <Navbar expand="md" className="menu">
        <div className="container-fluid d-flex d-md-none d-lg-none justify-content-around align-items-center">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            id="basic-navbar-nav"
          />
          <div className="logo" style={underStyle}>
            denicho
          </div>
          <div className="d-flex">
            {isLoggedIn || isLoggedInAdmin ? (
              <NavLink
                to={isLoggedIn ? "/administracion" : "/admin"}
                style={emprendedorButtonStyle}
              >
                Ir a mi perfil
              </NavLink>
            ) : (
              <NavLink to="/registro" style={emprendedorButtonStyle}>
                Registrarse
              </NavLink>
            )}
            <div
              onClick={isLoggedIn || isLoggedInAdmin ? handleLogout : handleLoginClick}
            >
              {isLoggedIn || isLoggedInAdmin ? (
                <div style={emprendedorButtonStyle3}>
                  <p>Logout</p>
                </div>
              ) : (
                <>
                  <div style={emprendedorButtonStyle3}>
                    <p>Login</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-around align-items-center"
        >
          <div className="divider d-flex d-md-none d-lg-none"></div>{" "}
          {/* Barra divisora horizontal */}
          <NavLink
            to="/"
            className="item-collapsed"
            style={{ textDecoration: "none" }}
          >
            <Typography text="Home" />
          </NavLink>
          <div className="vr d-none d-md-flex d-lg-flex "></div>
          <NavDropdown
            title={<Typography text="Categorías" />}
            id="basic-nav-dropdown"
            className="item-collapsed"
          >
            {categories.map((category) => (
              <NavDropdown.Item
                key={category.id}
                onClick={() => showEntrepreneurById(category.id)}
              >
                <Typography text={category.name} />
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <div className="vr d-none d-md-flex d-lg-flex "></div>
          <Nav.Link href="/emprendedores" className="item-collapsed">
            <Typography text="Emprendedores" />
          </Nav.Link>
          {/*<div className="vr d-none d-md-flex d-lg-flex "></div>
          <Nav.Link href="#sale" className="item-collapsed">
            <Typography text="Sale" />
          </Nav.Link>
            */}
          <div
            className="d-none d-md-flex d-lg-flex align-items-center"
            style={{ width: "20%" }}
          >
            <div className="vr" style={{ marginRight: "10%" }}></div>
            <Search></Search>
            <div className="vr" style={{ marginLeft: "10%" }}></div>
          </div>
          {/*<Nav.Link href="#cesta" className="d-flex align-items-center item-collapsed">
          <Typography text="Cesta" style={{marginRight: "5%"}}/>
          <FontAwesomeIcon
            icon={faShoppingBag}
            size="x" // Aumenta el tamaño del ícono
            style={{
              marginBottom: "5%"
            }}
            className="d-none d-md-flex d-lg-flex"
          />
        </Nav.Link>*/}
        </Navbar.Collapse>
      </Navbar>
      {isShowLogin && !isLoggedIn && (
        <Login
          handleLoginClick={handleLoginClick}
          setIsLoggedIn={setIsLoggedIn}
          setShowLogin={setShowLogin}
        />
      )}
    </>
  );
}

export default NavBar;
