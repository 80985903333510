import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { patchUpdateProduct } from "../../clients/phpApp";
import ModalEditImage from "./ModalEditImage";

const ModalEditProduct = (props) => {
  const { showEditModal, setEditedProduct, editedProduct, setDisableMessage, setShowEditModal, setProducts} = props;
  const [validated, setValidated] = useState(false);
  const [showModalModalImage, setShowModalImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageField, setImageField] = useState(null);

  const handleSaveEdit = async (event) => {
    try {
        event.preventDefault();
        event.stopPropagation();
  
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          setValidated(true);
          return;
        } else {
          setValidated(false);
        }
        const fields = ["active", "description", "name", "price", "image1", "image2", "image3"];
        const request = fields.reduce((acc, field) => {
          if (field.startsWith("image")) {
            // Si el campo es una imagen y es un archivo, agréguelo a la solicitud
            if (editedProduct[field] instanceof File) {
              acc[field] = editedProduct[field];
            } else if (typeof editedProduct[field] === 'string') {
              // Si el campo es una cadena y no es un archivo, omitir
              return acc;
            }
          } else {
            // Para otros campos, agréguelos a la solicitud
            acc[field] = editedProduct[field];
          }
        
          return acc;
        }, {});

        const updatedProduct = await patchUpdateProduct(request, editedProduct.id);
        if (updatedProduct.data.active === "1") {
          // Convertir la cadena '1' a un número 1
          updatedProduct.data.active = 1;
        } else {
          updatedProduct.data.active = 0;
        }

        setProducts((prevProducts) => prevProducts.map((product) => 
        product.id === updatedProduct.data.id ? updatedProduct.data : product
      ));
        setDisableMessage(null);
        handleCloseEditModal();
      } catch (error) {
        setDisableMessage("Error al editar producto. Inténtalo de nuevo.");
        handleCloseEditModal();
        console.error(error);
      }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditedProduct({});
    setValidated(false);
  };

  function handleImage(newImage) {
    setSelectedFile(newImage)
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [imageField]: newImage,
    }));
  }

  function closedModalImage() {
    setShowModalImage(false);
  }

  const handleImageSelection = (event, imgField) => {
    try {
        const file = event.target.files[0];
        const imageUrl =  URL.createObjectURL(file);
        setSelectedFile(imageUrl)
        setImageField(imgField)
        setShowModalImage(true)
    } catch (error) {
        return;
    }
  }

  const isValidPrice = () => {
    const value = editedProduct.price;
    return value !== "" && !isNaN(value) && parseFloat(value) >= 0;
  };
  
  const getPriceErrorMessage = () => {
    const value = editedProduct.price;
  
    if (value === "") {
      return "Este campo es obligatorio.";
    }
  
    if (isNaN(value) || parseFloat(value) < 0) {
      return "Por favor, ingrese un precio válido.";
    }
  
    return "";
  };

  return (
    <>
    <Modal show={showEditModal} onHide={handleCloseEditModal}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSaveEdit}
          id="editProduct"
        >
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre del Producto"
              value={editedProduct.name}
              onChange={(e) =>
                setEditedProduct({ ...editedProduct  , name: e.target.value })
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Este campo es obligatorio.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              placeholder="Descripción del Producto"
              value={editedProduct.description}
              onChange={(e) =>
                setEditedProduct({
                  ...editedProduct,
                  description: e.target.value,
                })
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Este campo es obligatorio.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Precio</Form.Label>
            <Form.Control
              type="number"
              placeholder="Precio del Producto"
              value={editedProduct.price}
              onChange={(e) =>
                setEditedProduct({ ...editedProduct, price: e.target.value })
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Este campo es obligatorio.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              value={editedProduct.active}
              onChange={(e) =>
                setEditedProduct({ ...editedProduct, active: e.target.value })
              }
              required
            >
              <option value=""></option>
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Este campo es obligatorio.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Imagen 1</Form.Label>
            <Form.Control
              id="editImage1"
              label="Cargar Imagen1"
              onChange={(e) => handleImageSelection(e, "image1")}
              accept="image/*"
              type="file"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Imagen 2</Form.Label>
            <Form.Control
              id="editImage2"
              label="Cargar Imagen2"
              onChange={(e) => handleImageSelection(e, "image2")}
              accept="image/*"
              type="file"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Imagen 3</Form.Label>
            <Form.Control
              id="editImage3"
              label="Cargar Imagen3"
              onChange={(e) => handleImageSelection(e, "image3")}
              accept="image/*"
              type="file"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEditModal}>
          Cancelar
        </Button>
        <Button variant="primary" type="submit" form="editProduct">
          Guardar Cambios
        </Button>
      </Modal.Footer>
      </Modal>
        {showModalModalImage && <ModalEditImage
        imageUrl={selectedFile}
        showModalImage={showModalModalImage}
        closedModalImage={closedModalImage}
        handleImage={handleImage}
      />}
      </>
  );
};

export default ModalEditProduct;
